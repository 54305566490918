.notfound-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.notfound-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    /* Ensure the title is prominent */
}

.notfound-message {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
    max-width: 600px;
    /* Limit message width for better readability */
}

.notfound-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    object-fit: contain;
    /* Ensure the image maintains aspect ratio */
}

.notfound-action-buttons {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    /* Align buttons in the center */
    flex-wrap: wrap;
    /* Allow buttons to wrap on smaller screens */
}

.notfound-search-input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
    /* Define a consistent width */
}

.notfound-back-button {
    padding: 10px 20px;
    background-color: #ff6666;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    /* Smooth transition */
}

.notfound-back-button:hover {
    background-color: #ff4c4c;
}

.notfound-contact-info {
    font-size: 16px;
    color: #555;
    margin-top: 20px;
    text-align: center;
}

.notfound-contact-info a {
    color: #ff6666;
    text-decoration: none;
    transition: color 0.3s ease;
    /* Smooth transition for hover effect */
}

.notfound-contact-info a:hover {
    text-decoration: underline;
    color: #ff4c4c;
    /* Change color on hover */
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .notfound-title {
        font-size: 28px;
    }

    .notfound-message {
        font-size: 16px;
    }

    .notfound-search-input {
        width: 100%;
        margin-bottom: 10px;
        /* Stack inputs vertically on mobile */
        margin-right: 0;
    }

    .notfound-back-button {
        width: 100%;
    }

    .notfound-action-buttons {
        flex-direction: column;
        /* Stack buttons on mobile */
    }
}